import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import { Link } from "react-router-dom";
import useFetch from "../../utils/useFetch"; 
import LoadingComponent from "../LoadingComponent/LoadingComponent";

import bg19 from "../../assets/images/background/bg-19.webp";

function Items() { 
  const [togglerState, setTogglerState] = useState({});
  const { data, loading, error } = useFetch("items/menu?fields=title_en,description_en,label_en,price,image.data,youtube,category.*,category.addons.*.*&sort=sort");

  if (loading) return <LoadingComponent />;
  if (error) return <div>Error: {error.message}</div>;

  const toggleLightbox = (youtubeId) => {
    setTogglerState(prevState => ({
      ...prevState,
      [youtubeId]: !prevState[youtubeId]
    }));
  };

  // Group items by category
  const groupedItems = data.data.reduce((acc, item) => {
    const categoryId = item.category.id;
    if (!acc[categoryId]) {
      acc[categoryId] = {
        category: item.category,
        items: [],
      };
    }
    acc[categoryId].items.push(item);
    return acc;
  }, {});

  // Sort categories and items by sort parameter
  const sortedCategories = Object.values(groupedItems).sort((a, b) =>
    a.category.sort - b.category.sort
  );

  sortedCategories.forEach((category) => {
    category.items.sort((a, b) => a.category.sort - b.category.sort);
  });

  return (
    <>
      {sortedCategories.map((category, key) => {
        return (
          <section className="menu-two" key={key}>
            <div className="right-bg">
              <img src={bg19} alt="" title="oranment" />
            </div>
            <div className="auto-container">
              <div className="title-box centered">
                <div className="pattern-image">
                  <img src={require("../../assets/images/icons/separator.svg").default} alt="mySvgImage" />
                </div>
                <h2>{category.category.title_en}</h2>
              </div>
              <div className="row clearfix">

                {category.items.map((item, key) => {
                  return (
                    <div className="menu-col col-lg-6 col-md-12 col-sm-12" key={key}>
                      <div className="inner">
                        <div className="dish-block" >
                          <div className="inner-box">
                            <div className="dish-image">
                                {item.image.data.full_url ? 
                                    <ReactFancyBox
                                      thumbnail={item.image.data.thumbnails[2].url}
                                      image={item.image.data.full_url}
                                    />
                                : null}

                                {item.youtube ?
                                  <>
                                    <button onClick={() => toggleLightbox(item.youtube)} className="youtube-play">
                                      <span className="icon fa fa-play"></span>
                                    </button>
                                    <FsLightbox
                                      toggler={togglerState[item.youtube] || false}
                                      sources={[`https://www.youtube.com/watch?v=${item.youtube}`]}
                                    />
                                  </>
                                  : null
                                }
                                
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">
                                    {item.title_en}
                                  </Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>${item.price}</span>
                              </div>
                            </div>
                            <div className="text desc">
                              {item.label_en ? <span className="s-info">{item.label_en}</span> : null}
                              <div dangerouslySetInnerHTML={{ __html: item.description_en }}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                )}

              </div>
              {category.category.addons.length > 0 ?
                <div className="row addons cleafix">
                    <div className="col-12 text-center mb-5">
                      <div className="title-box centered"></div>
                      <h3>{category.category.title_en} Add-ons</h3>
                    </div>
                  
                  {category.category.addons.map((item, key) => {
                    return (
                      <div className="menu-col col-lg-4 col-md-12 col-sm-12" key={key}>
                        <div className="inner m-0 p-0 mb-4">
                          <div className="dish-block m-0 p-0" >
                            <div className="title clearfix m-0 p-0">
                                <div className="ttl clearfix m-0">
                                  {item.addons_id.title_en}
                                </div>
                                <div className="price m-0">
                                  <span>${item.addons_id.price}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    )
                  })}
                </div>
              : null}
            </div>
          </section>
        )
      })}


    </>
  );
}

export default Items;
