import React, { useState } from "react";
import { Link } from 'react-router-dom'
import {makePostRequest} from '../../utils/telegram'
import DatePicker from "react-datepicker";
import subDays from "date-fns/subDays";
import "react-datepicker/dist/react-datepicker.css";
import bg5 from '../../assets/images/background/bg-5.png'
import bg6 from '../../assets/images/background/bg-6.png'

function Book() {
 
    const [startDate, setStartDate] = useState(new Date());
    const [response, setResponse] = useState(false);

    const telegramBotKey = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const chat_id = process.env.REACT_APP_TELEGRAM_USER_ID;

   

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 1; // 1 represents Monday
    };

    const sendNotification = async (text, parse_mode) => {
        const url = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
        const details = {
            chat_id,
            text,
            parse_mode
        };  
        const response = await makePostRequest(url, details);
        return response;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // For example, you can gather form data and send it to a server
        // console log all submited data
        const formData = new FormData(e.target);
        const formMessage = formData.get('message');
        const formName = formData.get('name');
        const formPhone = formData.get('phone');
        const formPersons = formData.get('persons');
        const formDate = formData.get('date');
        const formTime = formData.get('time');

        // convert data to html
        const html = `
-------------------
Reservation Request 🕔
Name: ${formName}
Phone: ${formPhone}
Persons: ${formPersons}
Date: ${formDate}
Time: ${formTime}
Message: ${formMessage}
-------------------
        `;

        // send notification
        sendNotification(html, 'html');

        // set response to true
        setResponse(true);


        

        // reset form
        //e.target.reset();



    };

    return (
        <>
            <section className="online-reservation inner-page">
                <div className="left-bg"><img src={bg5} alt="" title="" /></div>
                <div className="right-bg"><img src={bg6} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Reservation</span></div>
                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt="" title="" />
                        </div>

                        <h2>Book A Table</h2>
                        <div className="text desc">Reserve your spot in a culinary journey that awaits. Our easy-to-use booking page ensures you secure a table at your preferred time, allowing you to savor our dishes without the wait. </div>
                        <div className="text request-info">Booking request <Link to="tel:+1 321-294-4444">+1 321-294-4444</Link> or fill out the order form</div>
                    </div>
                    <div className="default-form reservation-form">
                        <form method="post" action="/" className="pr-0" onSubmit={handleSubmit}>
                            <div className="container">
                                <div className="row clearfix">
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                        <div className="field-inner">
                                            <input type="text" name="name" placeholder="Your Name" required />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                        <div className="field-inner">
                                            <input type="text" name="phone" placeholder="Phone Number" required />
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                        <div className="field-inner">
                                            <span className="alt-icon far fa-user"></span>
                                            <select className="l-icon" name="persons">
                                                <option>1 Person</option>
                                                <option>2 Person</option>
                                                <option>3 Person</option>
                                                <option>4 Person</option>
                                                <option>5 Person</option>
                                                <option>6 Person</option>
                                                <option>7 Person</option>
                                            </select>
                                            <span className="arrow-icon far fa-angle-down"></span>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                        <div className="field-inner">
                                            <span className="alt-icon far fa-calendar"></span>
                                            <DatePicker
                                                className="l-icon datepicker"
                                                name="date"
                                                selected={startDate}
                                                onChange={date => setStartDate(date)}
                                                excludeDates={[new Date(), subDays(new Date(), 1)]}
                                                filterDate={isWeekday}
                                            />

                                            <span className="arrow-icon far fa-angle-down"></span>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                        <div className="field-inner">
                                            <span className="alt-icon far fa-clock"></span>
                                            <select className="l-icon" name="time">

                                                <option>11 : 00 am</option>
                                                <option>12 : 00 pm</option>
                                                <option>01 : 00 pm</option>
                                                <option>02 : 00 pm</option>
                                                <option>03 : 00 pm</option>
                                                <option>04 : 00 pm</option>
                                                <option>05 : 00 pm</option>
                                                <option>06 : 00 pm</option>
                                                <option>07 : 00 pm</option>
                                                <option>08 : 00 pm</option>
                                                <option>09 : 00 pm</option>

                                            </select>
                                            <span className="arrow-icon far fa-angle-down"></span>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                        <div className="field-inner">
                                            <textarea name="message" placeholder="Message" required></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                        <div className="field-inner">

                                            <button type="submit" className="theme-btn btn-style-one clearfix">
                                                <span className="btn-wrap">
                                                    <span className="text-one">book a table</span>
                                                    <span className="text-two">book a table</span>
                                                </span>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {response && (
                        <div className="alert alert-success mt-5" role="alert">
                            Your reservation request has been sent successfully!
                        </div>
                        )}

                    </div>
                </div>

                <div className="map-location-section">
                    <div className="auto-container">
                        <div className="outer-box">
                            <div className="row clearfix">
                                <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14031.488131991719!2d-81.3638745!3d28.4532737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77d4b530ffc15%3A0x297711e7a699ea8f!2sCARAVAN%20UZBEK%20%26%20TURKISH%20CUISINE!5e0!3m2!1sro!2s!4v1695397338883!5m2!1sro!2s" width="100%" height="600" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Google Maps"></iframe>
                                    </div>
                                </div>
                                <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                    <div className="inner">
                                        <div className="title">
                                            <h2>Orlando, FL</h2>
                                        </div>
                                        <div className="data">
                                            <ul className="info">
                                                <li>
                                                    <strong>Contact Us</strong><br />
                                                    8015 S Orange ave, Orlando, United States, Florida<br />
                                                    Call : +1 321-294-4444 <br />
                                                    Email : info@caravanhalal.com
                                                </li>
                                                <div className="separator"><span></span></div>
                                                <li><strong>Working Hours</strong><br />Tuesday to Sunday <br />11.00 am - 09.00pm</li>
                                                <li><strong>Turkish Breakfast</strong><br />Coming Soon</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Book