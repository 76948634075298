import React from 'react'
import Backgroundimage from '../../assets/images/background/image-12.jpg';

function Quotesection() {
  return (
    <>
      <section className="intro-section quote">
        <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
        <div className="auto-container">
          <div className="content-box">
            <h3>Cooking is an art, and at our Uzbek restaurant, every dish is a canvas painted with the vibrant flavors of Central Asia.</h3>
            <div className="separator"><span></span><span></span><span></span></div>
            <div className="auth-title">Furkat Meliev - Chef</div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Quotesection