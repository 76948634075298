import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import './assets/css/style.css';
import './assets/css/responsive.css'
import { ConfigContext } from './configContext.js';
import useFetch from "./utils/useFetch";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";

export default function App() {

  const { data, loading, error } = useFetch("items/config");
  if (loading) return <LoadingComponent />;
  if (error) return <div>Error: {error.message}</div>;
  
  const config = data.data[0];


  return (
    <>
      <Router>
        <ConfigContext.Provider value={{config}}>
          <Routing />
        </ConfigContext.Provider>
      </Router>
    </>
  );
}
