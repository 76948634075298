import React from 'react'
import { Link } from 'react-router-dom'
import bg3 from '../../assets/images/background/bg-3.webp'
import badge1 from '../../assets/images/resource/badge-1.webp'
import img1 from '../../assets/images/resource/image-1.webp'
import img2 from '../../assets/images/resource/image-2.webp'

function Story() {
    return (
        <>
            <section className="story-section">
                <div className="left-bg">
                    <img src={bg3} alt="" title="" />
                </div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="title-box centered">
                                    <div className="subtitle">
                                        <span> Our story </span>
                                    </div>
                                    <div className="pattern-image">
                                        <img src={require('../../assets/images/icons/separator.svg').default} alt="Separator" />
                                    </div>
                                    <h2>Every Flavor Tells a Story</h2>
                                    <div className="text">Welcome to Caravan, where the rich tapestry of Uzbek and Turkish flavors come alive.<br /> Our menu is a harmonious blend of traditional Uzbek and Turkish dishes, crafted with love, using age-old recipes passed down through generations.<br /> Join us on a gastronomic journey that celebrates the spirit of camaraderie and the joy of shared meals. </div>
                                </div>
                                <div className="booking-info">
                                    <div className="bk-title">Book Through Call</div>
                                    <div className="bk-no"><Link to="tel:+1 321-294-4444">+1 321-294-4444</Link></div>
                                    <div className="link-box">
                                        <Link to="/about" className="theme-btn btn-style-two clearfix">
                                            <span className="btn-wrap">
                                                <span className="text-one">Read More</span>
                                                <span className="text-two">Read More</span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="round-stamp">
                                    <img src={badge1} alt="Halal" />
                                </div>
                                <div className="images parallax-scene-1">
                                    <div className="image" data-depth="0.15"><img src={img1} alt="Halal" /></div>
                                    <div className="image" data-depth="0.30"><img src={img2} alt="Halal" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Story