import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.min.css';

import useFetch from "../../utils/useFetch";
import LoadingComponent from "../LoadingComponent/LoadingComponent";


SwiperCore.use([Navigation]);


function Special() {

  const { data, loading, error } = useFetch("items/menu?fields=title_en,description_en,label_en,price,image.data&filter[best]=1&sort=sort");

  if (loading) return <LoadingComponent />;
  if (error) return <div>Error: {error.message}</div>;


  const options = {
    slidesPerView: 4,
    spaceBetween: 45,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      600: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  console.log(data);
 

  return (
    <>
      <section className="special-offer">
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>special offer</span>
              </div>
              <div className="pattern-image">
                <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
              </div>
              <h2>Best Specialties</h2>

            </div>
            <Swiper className="dish-gallery-slider" {...options}
              loop={true}
              spaceBetween={45}
              slidesPerView={4}
              navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
            >

              {data.data.map((item, key) => {
                return (
                <SwiperSlide className="offer-block-two" key={key}>
                  <div className="inner-box">
                    <div className="image">
                      <Link to="/menu" rel="preconnect">
                        {item.image.data.full_url ? <img src={item.image.data.thumbnails[2].url} alt={item.title_en} width="100%" height="100%" /> : null}
                      </Link>
                    </div>
                    <h4>
                      <Link to="/menu" rel="preconnect">{item.title_en}</Link>
                    </h4>
                    <div className="text desc">
                      <div dangerouslySetInnerHTML={{ __html: item.description_en }}></div>
                    </div>
                    <div className="price">${item.price}</div>
                  </div>
                </SwiperSlide>
                )
              })}

            </Swiper>
            <div className="swiper-button-prev">
              <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
              <span className="fal fa-angle-right"></span>
            </div>
            <div className="lower-link-box text-center">
              <Link to="/menu" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">view all menu</span>
                  <span className="text-two">view all menu</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}

export default Special;
