import React from "react";

function Disclaimer() {
    return (
      <>
        <section className="menu-two">
            <div className="auto-container">
                <div className="about-section">
                    <div className="about-block info-block">
                        <div className="inner-box wow fadeInUp text-center p-5" data-wow-duration="1500ms" data-wow-delay="600ms">                
                        Before placing your order, please inform your server if a person in your party has a food allergy.<br />
                        This will help us take necessary precautions and provide guidance on menu items that may be suitable for you.<br />
                        Your safety is our utmost priority, and we appreciate your understanding and cooperation in this matter.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Disclaimer