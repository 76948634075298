import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Home from '../components/Home/Main';
import About from '../components/About/Main';
import Chefs from '../components/Chef/Main';
import Contact from '../components/Contact/Main';
import Menu from '../components/Menu/Main';
import Book from '../components/Book/Main'

function Index() {

  
  const location = useLocation();
  
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/ourchefs" element={<Chefs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reservation" element={<Book />} />
      </Routes>
      <Footer />
    </>
  )
}

export default Index