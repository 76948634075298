import React from 'react'
import Reservation from './Reservation'
import Items from './Items'
import Disclaimer from './Disclaimer'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-2.webp'

function Menuthree() {
  return (
    <>
      <Bredcrumb subtitle="Our Menu" title="DELICIOUS & AMAZING" Img={Img} />
      <Items />
      <Disclaimer />
      <Reservation />
    </>
  )
}

export default Menuthree