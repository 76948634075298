import React from 'react';
import Backgroundimage from '../../assets/images/background/image-7.webp';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation } from 'swiper/core';
import quotesone from '../../assets/images/icons/quotes-1.webp';
import authorthumb from '../../assets/images/resource/author-thumb-4.webp';
import authorthumbone from '../../assets/images/resource/author-thumb-5.webp';
import authorthumbtwo from '../../assets/images/resource/author-thumb-6.webp';

SwiperCore.use([Navigation]);

function Testimonialsabout() {
  const show = {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <section className="testimonials-two">
      <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}></div>
      <div className="auto-container">
        <div className="title-box centered">
          <div className="subtitle">
            <span>testimonials</span>
          </div>
          <div className="pattern-image">
            <img src={require('../../assets/images/icons/separator.svg').default} alt="mySvgImage" />
          </div>
          <h2>What People Are Saying</h2>
        </div>

        <div className="carousel-box">
          <Swiper className="testimonial-slider" {...show}>
            <SwiperSlide className="testi-block item">
              <div className="inner-box">
                <div className="quote-icon">
                  <img src={quotesone} alt="" title="" />
                </div>
                <div className="rating">
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </div>
                <div className="text">
                Finally! I was waiting for the opening for a while. But wait time didn’t disappoint. The food is delicious, the design is super comfortable and the atmosphere brings my childhood memories. I can recommend this restaurant to anyone who missed Turkish or Uzbek cousins. Plus all food is 100% halal which is very rare in FL.
                </div>
                <div className="auth-info">
                  <div className="image">
                    <img src={authorthumb} alt="" />
                  </div>
                  <div className="auth-title">Даудбек Турдыбеков</div>
                  <div className="location">Google</div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="testi-block item">
              <div className="inner-box">
                <div className="quote-icon">
                  <img src={quotesone} alt="" title="" />
                </div>
                <div className="rating">
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </div>
                <div className="text">
                  Great restaurant. Their food is very excellent. I advise all Orlando residents and tourists to visit it. Uzbek food is halal. The meat is clean. The food is delicious. The seating area is great, clean and spacious. There are parking spaces. There are bathrooms. I highly recommend it
                </div>
                <div className="auth-info">
                  <div className="image">
                    <img src={authorthumbone} alt="" />
                  </div>
                  <div className="auth-title">يوسف صلواتي</div>
                  <div className="location">Google</div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="testi-block item">
              <div className="inner-box">
                <div className="quote-icon">
                  <img src={quotesone} alt="" title="" />
                </div>
                <div className="rating">
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                  <span className="fa fa-star"></span>
                </div>
                <div className="text">
                Truly authentic Uzbek cuisine.<br />100% Zabiha halal.<br />Great service, atmosphere with kind and dedicated people.<br />Highly recommend to anyone staying or visiting Orlando!!!
                </div>
                <div className="auth-info">
                  <div className="image">
                    <img src={authorthumbtwo} alt="" />
                  </div>
                  <div className="auth-title">Elyorbek Yulchiev</div>
                  <div className="location">Google</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="swiper-button-prev">
              <span className="fal fa-angle-left"></span>
            </div>
            <div className="swiper-button-next">
              <span className="fal fa-angle-right"></span>
            </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonialsabout;
