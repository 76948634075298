import React, { useContext, useState } from "react";
import { makePostRequest } from '../../utils/telegram'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'
import Bgone from '../../assets/images/background/image-10.webp'
import { ConfigContext } from '../../configContext'

function Reservation() {
    const [startDate, setStartDate] = useState(new Date());
    const [response, setResponse] = useState(false);

    const config = useContext(ConfigContext);

    const telegramBotKey = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const chat_id = process.env.REACT_APP_TELEGRAM_USER_ID;


    const sendNotification = async (text, parse_mode) => {
        const url = `https://api.telegram.org/bot${telegramBotKey}/sendMessage`;
        const details = {
            chat_id,
            text,
            parse_mode
        };
        const response = await makePostRequest(url, details);
        return response;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // For example, you can gather form data and send it to a server
        // console log all submited data
        const formData = new FormData(e.target);
        const formMessage = formData.get('message');
        const formName = formData.get('name');
        const formPhone = formData.get('phone');
        const formPersons = formData.get('persons');
        const formDate = formData.get('date');
        const formTime = formData.get('time');

        // convert data to html
        const html = `
-------------------
Reservation Request 🕔
Name: ${formName}
Phone: ${formPhone}
Persons: ${formPersons}
Date: ${formDate}
Time: ${formTime}
Message: ${formMessage}
-------------------
        `;

        // send notification
        sendNotification(html, 'html');

        // set response to true
        setResponse(true);




        // reset form
        //e.target.reset();



    };

    return (
        <>
            <section className="reserve-section style-two">
                <div className="image-layer" style={{ backgroundImage: `url(${Bgone})` }}></div>
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Online Reservation</h2>
                                        <div className="request-info">Booking request <Link to={`tel:${config.config.phone}`} aria-label="Call Now">{ config.config.phone }</Link> or fill out the order form</div>
                                    </div>
                                    <div className="default-form reservation-form">
                                        <form method="post" action="/" className="pr-0" onSubmit={handleSubmit}>
                                            <div className="container">
                                                <div className="row clearfix">
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <input type="text" name="name" placeholder="Your Name" required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <input type="text" name="phone" placeholder="Phone Number" required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-user"></span>
                                                            <select className="l-icon" name="persons">
                                                                <option>1 Person</option>
                                                                <option>2 Person</option>
                                                                <option>3 Person</option>
                                                                <option>4 Person</option>
                                                                <option>5 Person</option>
                                                                <option>6 Person</option>
                                                                <option>7 Person</option>
                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-calendar"></span>
                                                            <DatePicker
                                                                className="l-icon datepicker"
                                                                name="date"
                                                                selected={startDate}
                                                                onChange={date => setStartDate(date)}
                                                            />

                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-4 col-md-12 col-sm-12">
                                                        <div className="field-inner">
                                                            <span className="alt-icon far fa-clock"></span>
                                                            <select className="l-icon" name="time">

                                                                <option>11 : 00 am</option>
                                                                <option>12 : 00 pm</option>
                                                                <option>01 : 00 pm</option>
                                                                <option>02 : 00 pm</option>
                                                                <option>03 : 00 pm</option>
                                                                <option>04 : 00 pm</option>
                                                                <option>05 : 00 pm</option>
                                                                <option>06 : 00 pm</option>
                                                                <option>07 : 00 pm</option>
                                                                <option>08 : 00 pm</option>
                                                                <option>09 : 00 pm</option>

                                                            </select>
                                                            <span className="arrow-icon far fa-angle-down"></span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                        <div className="field-inner">
                                                            <textarea name="message" placeholder="Message" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                                        <div className="field-inner">

                                                        {config.config.reservation && (
                                                        <button type="submit" className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">book a table</span>
                                                                <span className="text-two">book a table</span>
                                                            </span>
                                                        </button>
                                                        )}

                                                        {!config.config.reservation && (
                                                        <span className="text-danger">Reservation is disabled</span>
                                                        )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {response && (
                                            <div className="alert alert-success mt-5" role="alert">
                                                Your reservation request has been sent successfully!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no"><Link to={`tel:${config.config.phone}`} aria-label="Call Now">{ config.config.phone }</Link></div>
                                        </div>
                                        <div className="separator"><span></span></div>
                                        <ul className="info">
                                            <li><strong>Location</strong><br />{ config.config.address }</li>
                                            <li>
                                                <strong>Schedule</strong><br />
                                                <span dangerouslySetInnerHTML={{ __html: config.config.schedule }}></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Reservation