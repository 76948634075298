import React, { useContext } from 'react';
import Backgroundimage from '../../assets/images/background/image-4.webp';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/logo.webp'
import { ConfigContext } from '../../configContext'

function Main() {
    const config = useContext(ConfigContext);
    return (
        <>
            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo">
                                            <Link to="/" title="Caravan - Uzbek & Turkish Cusine">
                                                <img src={logonew} alt="Caravan - Uzbek & Turkish Cusine" title="Caravan - Uzbek & Turkish Cusine" />
                                            </Link>
                                        </div>
                                        <div className="info">
                                            <ul>
                                                <li>{config.config.address}</li>
                                                <li><Link to={`mailto:${config.config.email}`}>{config.config.email}</Link></li>
                                                <li><Link to={`tel:${config.config.phone}`}>Booking Request : {config.config.phone}</Link></li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="newsletter">
                                            <h3>Get News & Offers</h3>
                                            <div className="text">Subscribe us & Get <span>last offers</span></div>
                                            <div className="newsletter-form">
                                                <form method="post" action="/">
                                                    <div className="form-group">
                                                        <span className="alt-icon far fa-envelope"></span>
                                                        <input type="email" name="email" placeholder="Your email" required />
                                                        <button type="submit" className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">subscribe</span>
                                                                <span className="text-two">subscribe</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/menu">Menus</Link></li>
                                        <li> <Link to="/about">About us</Link></li>
                                        <li> <Link to="/ourchefs">Our chefs</Link></li>
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="https://www.facebook.com/caravanhalal">facebook</Link></li>
                                        <li> <Link to="https://www.instagram.com/caravanhalal/">instagram</Link></li>
                                        <li> <Link to="https://www.youtube.com/@caravanhalal">Youtube</Link></li>
                                        <li> <Link to="https://maps.app.goo.gl/2WGRTrhH5NJ7VXAL9">Google map</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2023 Caravan - Uzbek & Turkish Cuisine. All Rights Reserved   |    Crafted by <Link to="https://nikba.md" target="blank">Nikba Creative Studio</Link></div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>
    )
}

export default Main