import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Iframe from 'react-iframe'
import bg25 from '../../assets/images/background/bg-25.png'
import bg6 from '../../assets/images/background/bg-6.png'
import restro from '../../assets/images/resource/restaurant.png'
import { ConfigContext } from '../../configContext'

function Contactbanner() {
    const config = useContext(ConfigContext);
    return (
        <>
            <div className="contact-map">
                <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14031.488131991719!2d-81.3638745!3d28.4532737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77d4b530ffc15%3A0x297711e7a699ea8f!2sCARAVAN%20UZBEK%20%26%20TURKISH%20CUISINE!5e0!3m2!1sro!2s!4v1695397338883!5m2!1sro!2s" width="100%" height="500" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
            </div>

            <section className="contact-page">
                <div className="left-bg"><img src={bg25} alt="" title="" /></div>
                <div className="right-bg"><img src={bg6} alt="" title="" /></div>

                <div className="location-center">
                    <div className="auto-container">
                        <div className="cinfo-box">
                            <div className="row clearfix">

                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Turkish Breakfast</h4>
                                        <div className="text"> Coming Soon</div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box cp-seprator wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                        <h4>Contact Info</h4>
                                        <div className="text">{ config.config.address }<br />Email : { config.config.email }</div>
                                        <div className="more-link"><Link to={`tel:${config.config.phone}`}>Booking : { config.config.phone }</Link></div>
                                    </div>
                                </div>


                                <div className="contactinfo-block col-lg-4 col-md-4 col-sm-12">
                                    <div className="inner-box wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="600ms">
                                        <h4>Working Hours</h4>
                                        <div className="text">
                                            <span dangerouslySetInnerHTML={{ __html: config.config.schedule }}></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="auto-container">
                    <div className="c-page-form-box">
                        <div className="row clearfix">

                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <div className="title-box centered">
                                    <h2>Message us</h2>
                                    <div className="text desc">Have a question about the our service? We're here to help, contact us today</div>
                                </div>
                                <div className="default-form reservation-form">
                                    <form method="post" action="/">
                                        <div className="clearfix">
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input type="text" name="fieldname" placeholder="Your Name" required="" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="field-inner">
                                                    <input type="text" name="fieldname" placeholder="Your Email" required="" />
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <input type="text" name="fieldname" placeholder="Phone Number" required="" />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="field-inner">
                                                    <textarea name="fieldname" placeholder="Special Request" required=""></textarea>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                {config.config.reservation && (
                                                    <button type="submit" className="theme-btn btn-style-one clearfix">
                                                        <span className="btn-wrap">
                                                            <span className="text-one">book a table</span>
                                                            <span className="text-two">book a table</span>
                                                        </span>
                                                    </button>
                                                )}

                                                {!config.config.reservation && (
                                                <span className="text-danger">Reservation is disabled</span>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                                <img src={restro} alt="" />
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Contactbanner