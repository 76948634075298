import React from 'react'
import { useContext } from 'react'
import Special from '../Menhome/Special'
import Contact from '../Menhome/Contact'
import Banner from '../Menhome/Banner'
import Offer from '../Menhome/Offer'
import Story from '../Menhome/Story'

import Whyus from '../Menhome/Whyus'
import { Link } from 'react-router-dom'
import sidebarlogo from '../../assets/images/resource/sidebar-logo.png'
import { ConfigContext } from '../../configContext'


function Main() {
    const config = useContext(ConfigContext);

    return (
        <>
            <div className="menu-backdrop"></div>
            <section className="hidden-bar">
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer">
                        <span className="far fa-close"></span>
                    </div>
                    <div className="logo-box">
                        <Link to="/" title="Caravan - Uzbek & Turkish Cusine">
                            <img src={sidebarlogo} alt="Caravan - Uzbek & Turkish Cusine" title="Caravan - Uzbek & Turkish Cusine" />
                        </Link>
                    </div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li className="current"><Link to="/">Home</Link></li>
                            <li><Link to="/menu">Menu</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/ourchefs">Our chefs</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
 
                    <h2>Visit Us</h2>
                    <ul className="info">
                        <li>{ config.address }</li>
                        <li>dangerouslySetInnerHTML={ config.schedule }</li>
                        <li><Link to={`mailto:${config.email}`}>{ config.email }</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Booking request</div>
                        <div className="bk-no"><Link to={`tel:${config.phone}`}>{ config.phone }</Link></div>
                    </div>
                </div>
            </section>

            <Banner />
            <Offer />
            <Story />
            <Special />
            <Contact />
            <Whyus />
        </>
    )
}

export default Main