import React from 'react'
import Aboutsec from '../Menabout/Aboutsec'
import Aboutwhyus from '../Menabout/Aboutwhyus'
import Sliderabout from '../Menabout/Sliderabout'
import Testimonialsabout from '../Menabout/Testimonialsabout'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-1.webp'

function Main() {
  return (
    <>
      <Bredcrumb subtitle="About Us" title="OUR STORY" Img={Img} />
      <Aboutsec />
      <Testimonialsabout />
      <Aboutwhyus />
      <Sliderabout />
    </>
  )
}
export default Main