import React from 'react'
import bg26 from '../../assets/images/background/bg-26.png'
import mainchef from '../../assets/images/resource/mainchef.jpg'

function Chefsection() {
    return (
        <>
            <section className="chef-section">
                <div className="right-bg"><img src={bg26} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="image-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="image"><img src={mainchef} alt="" /></div>
                            </div>
                        </div>

                        <div className="content-col col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="inner clearfix wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="content-box">
                                    <div className="title-box">
                                        <div className="subtitle"><span>15 year of experience</span></div>
                                        <div className="pattern-image">
                                            <img src={require('../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                                        </div>
                                        <h2>Furkat Meliev</h2>
                                        <div className="text">Furkat Meliev, a culinary maestro with a passion for flavors, has been leading the kitchen at Caravan, a renowned Uzbek & Turkish eatery.<br /><br /> His expertise in blending spices and traditional techniques has made him a prominent figure in the gastronomic world. <br /><br />Since joining Caravan, Furkat has introduced a myriad of dishes that reflect the rich heritage of Central Asian and Anatolian cuisines.</div>
                                    </div>
                                    <div className="info">
                                        <div className="author">CHEF</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Chefsection